<template>
  <error-assign-machine />
</template>

<script>
import store from '@/store'
const ErrorAssignMachine = () => import('@/components/Machines/ErrorAssignMachine')

export default {
  name: 'ErrorAssignMachineView',
  components: {
    ErrorAssignMachine
  },
  beforeRouteEnter (to, from, next) {
    if (store.state.User.userData.role === 'OPERATOR' || store.state.User.userData.role === 'MAINTAINER') {
      next('/dashboard')
    } else {
      next()
    }
  }
}
</script>
